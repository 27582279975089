import React, { useEffect, useState } from 'react';
import { Header, Title, Footer } from './modules/tp4';
import BreadCrumbs from './modules/BreadCrumbs';
import LocationsModal from './modules/LocationsModal';
import Spinner from './modules/Spinner';
import Search from './modules/Search';
import Topics from './modules/Topics';
import CheckBox from './modules/CheckBox';
import Footnotes from './modules/Footnotes';
import { getMetadata } from './utils/api';
import { getQueryParam, asArray, insertQueryParam, env } from './utils/helpers';
import * as analytics from './utils/analytics';

const crumbs = [
  {
    label: 'CDC',
    route: env('cdc'),
  },
  {
    label: 'Division of Nutrition, Physical Activity, and Obesity',
    route: env('cdc') + '/nccdphp/dnpao/index.html',
  },
  {
    label: 'Nutrition, Physical Activity, and Obesity: Data, Trend, and Maps',
    route: env('cdc') + '/dnpao-data-trends-maps/database/index.html',
  },
];

function App() {
  const [metadata, setMetadata] = useState(undefined);
  const [locationIds, setLocationIdsState] = useState([]);
  function setLocationIds(locations) {
    // set window.history
    insertQueryParam('locationIds', locations.join(','));
    analytics.changeLocation({ locationIds: locations });
    // Set the location Ids in the App.js
    setLocationIdsState(locations);
  }
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCount, setSearchCount] = useState(undefined);
  let [openAll, setAllOpen] = useState(undefined);
  const [openedTopics, setOpenedTopics] = useState(new Map());
  let [showOptions, setShowOptions] = useState(false);
  let [options, setOptions] = useState({
    ci: true,
    weightedNum: true,
    missingData: false,
  });
  function handleOptionsChange(e) {
    const { value, checked } = e.target;
    const newOptions = { ...options, [value]: checked };
    analytics.changeOptions(newOptions);
    setOptions(newOptions);
  }
  useEffect(() => {
    // this is runs only once when the component mounts.
    // set locations based on URL
    const ids = asArray(getQueryParam('locationIds'));
    setLocationIds(ids.length ? ids : ['59']);
    // we will use this to cache our metadata call.
    getMetadata().then((response) => {
      setMetadata(response);
    });
    // ANALYTICS
    analytics.pageLoad();
  }, []);
  return (
    <>
      <a class="sr-only sr-only-focusable" href="#content">
        Skip to main content
      </a>
      <Header />
      <Title title="Nutrition, Physical Activity, and Obesity: Data, Trends and Maps" />
      <div className="container-fluid site-title border-none p-0">
        <div className="container">
          <div className="row pt-1 pb-1">
            {/* <div className="col-8">
              <nav className="d-flex justify-content-between">
                <a
                  href={
                    env('cdc') + '/dnpao-data-trends-maps/database/index.html'
                  }
                >
                  Home
                </a>
                <a
                  href={
                    env('nccd') +
                    '/dnpao_dtm/rdPage.aspx?rdReport=DNPAO_DTM.ExploreByTopic'
                  }
                >
                  Explore by Topic
                </a>
                <a
                  href={
                    env('nccd') +
                    '/dnpao_dtm/rdPage.aspx?rdReport=DNPAO_DTM.ExploreByLocation'
                  }
                >
                  Explore by Location
                </a>
                <a href="#">Comparison Report</a>
              </nav>
            </div> */}
            <div className="col-12">
              <nav className="text-right">
                <a href="https://data.cdc.gov/browse?category=Nutrition%2C+Physical+Activity%2C+and+Obesity">
                  Data Portal
                </a>{' '}
                |{' '}
                <a
                  href={
                    env('cdc') +
                    '/dnpao-data-trends-maps/database/index.html#cdc_generic_section_7-more-information'
                  }
                >
                  Help
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <BreadCrumbs {...{ crumbs }} />
      <main id="content" className="container bg__white">
        {/* MAIN Content*/}
        {!metadata ? (
          <div className="d-flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          <>
            <h3>Comparison Report</h3>
            <div className="row m-0 border bg-light p-3">
              <div className="col-9">
                <Search
                  {...{
                    setSearchTerm,
                    setAllOpen,
                    searchCount,
                    setSearchCount,
                  }}
                />
              </div>
              <div className="col-3">
                <LocationsModal
                  {...{
                    locations: metadata.Location,
                    locationIds,
                    setLocationIds,
                    setAllOpen,
                  }}
                />
              </div>
              <div className="col-3"></div>
              <div className="col-12">
                <span
                  onClick={() => setShowOptions(!showOptions)}
                  role="button"
                  tabIndex="0"
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') setShowOptions(!showOptions);
                  }}
                  className="link"
                >
                  {showOptions ? '- Hide' : '+ Show'} Options
                </span>
              </div>

              {showOptions && (
                <div className="col-12 border bg-white p-3">
                  <CheckBox
                    {...{
                      value: 'ci',
                      id: 'ci',
                      label: 'Show Confidence Interval',
                      handleChange: handleOptionsChange,
                      checked: options.ci,
                    }}
                  />
                  <CheckBox
                    {...{
                      value: 'weightedNum',
                      id: 'weightedNum',
                      label: 'Show Sample Size',
                      handleChange: handleOptionsChange,
                      checked: options.weightedNum,
                    }}
                  />
                  <CheckBox
                    {...{
                      value: 'missingData',
                      id: 'missingData',
                      label: 'Hide Missing Data Rows',
                      handleChange: handleOptionsChange,
                      checked: options.missingData,
                    }}
                  />
                  Display options will not be applied to the CSV export.
                </div>
              )}
            </div>
          </>
        )}
        {!!metadata && !!locationIds.length && (
          <>
            <Topics
              {...{
                metadata,
                locationIds,
                searchTerm,
                openAll,
                setAllOpen,
                setSearchCount,
                options,
                setLocationIds,
                openedTopics,
                setOpenedTopics,
              }}
            />
            <Footnotes {...metadata} />
          </>
        )}
      </main>
      <Footer />
    </>
  );
}

export default App;
